import {
  ChangeDetectorRef,
  Component, ElementRef, Input, OnDestroy, OnInit
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TagsDialogComponent } from 'src/app/surveys/tags-dialog/tags-dialog.component';

interface Tag {
  name: string;
  type: 'default' | 'custom' | 'cancelled' | 'targeted' | 'gps';
}

@Component({
  selector: 'app-tags-cell-display',
  templateUrl: './tags-cell-display.component.html',
  styleUrls: ['./tags-cell-display.component.scss']
})
export class TagsCellDisplayComponent implements OnInit, OnDestroy {  
  private resizeObserver!: ResizeObserver;

  containerSize = { width: 0, height: 0 };

  showMoreTag = false;

  hiddenTags: string[] = [];

  hiddenTagsDisplay = '';
  
  @Input() tags: Tag[] = [];

  @Input() contextName = '';

  readonly moreTagWidth = 48;

  constructor(
    private hostElement: ElementRef<HTMLElement>, 
    private cd: ChangeDetectorRef,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    // Initialize ResizeObserver
    this.resizeObserver = new ResizeObserver((entries) => {
      this.showMoreTag = this.tags?.length > 0;
      for (const entry of entries) {
        const { width, height } = entry.contentRect;
        this.containerSize = { width, height }; // Update size
        this.adjustTags(this.tags);
      }
    });

    // Observe the host element
    this.resizeObserver.observe(this.hostElement.nativeElement);    
  }

  adjustTags(tags: Tag[]) {
    const baseTagListOrder = tags.map((tag: Tag) => tag.name);
    this.hiddenTags = [];
    if (this.containerSize.width === 0) {
      return;
    }
    let totalWidth = 7; // Adjust for rounding errors
    let hideNextTag = false;
    let lastDisplayedTagIndex = -1;
    let isMoreTagDisplayed = false;
    
    const tagElements = Array.from(this.hostElement.nativeElement.children) as HTMLElement[];

    for (let i = 0; i < tagElements.length; i++) {
      const tag = tagElements[i];
      if (tag.id === 'moreTag') {
        // Display the more tag when it's not the first and only tag and it's not defined as hidden
        tag.style.display = (i === 0 || !hideNextTag) ? 'none' : 'inline-flex';
        if (hideNextTag && i - 1 >= 0) { // Hide next (more) tag that is not the last tag.
          // "Hide next tag" means there is no room to display the previous & current tag,.
          // Therefore, we should instead, hide the previous tag and leave the more tag visible.
          tagElements[i - 1].style.display = 'none';
        }
        isMoreTagDisplayed = true;
        break;        
      }
      if (hideNextTag) {
        this.hideTag(tag);
        // eslint-disable-next-line no-continue
        continue;
      }

      const tagWidth = this.getTagWidth(tag);
      const nextTag = this.getNextTag(tagElements, i);
      const nextTagWidth = this.getTagWidth(nextTag);

      // Hide the next and subsequent proper tags when including the next tag exceeds the container boundary
      if (totalWidth + tagWidth + nextTagWidth > this.containerSize.width) {
        hideNextTag = true;            
      }
      totalWidth += tag.style.display !== 'none' ? tagWidth : 0;
      lastDisplayedTagIndex = tag.style.display !== 'none' ? i : lastDisplayedTagIndex;
    }

    // When the more tag (the last special tag) is displayed, determine whether the last displayed proper tag has enough room and should be hidden
    if (totalWidth + this.moreTagWidth > this.containerSize.width && isMoreTagDisplayed) {
      this.hideTag(tagElements[lastDisplayedTagIndex]);
    }

    // The hidden tags will be sorted based on the original tags order.
    // This is done because we can not guarantee the order the hidden tags added.
    const sortedArray = this.hiddenTags.sort((a, b) => baseTagListOrder.indexOf(a) - baseTagListOrder.indexOf(b));
    this.hiddenTags = [...sortedArray];
    this.hiddenTagsDisplay = this.hiddenTags.length > 0 ? sortedArray.join(', ') : '';
    this.cd.detectChanges(); // HACK! title was not binding to hidden tags display value
  }

  hideTag(tag: HTMLElement | undefined) {
    if (!tag) {
      return;
    }
    tag.style.display = 'none';
    this.hiddenTags.push(tag.innerText);
  }

  getNextTag(tags: HTMLElement[], currentIndex: number): HTMLElement | undefined {
    return currentIndex + 1 < tags.length ? tags[currentIndex + 1] : undefined;
  }

  getTagWidth(tag: HTMLElement | undefined): number {
    if (!tag) {
      return 0;
    }
    // Ensure tag is visible to obtain its width and margins
    tag.style.display = 'inline-flex';
    return tag?.offsetWidth ? (tag.offsetWidth + 8) : 0;
  }

  openTagsDialog(event) {
    event.stopPropagation();
    console.log('openTagsDialog');
    this.dialog.open(TagsDialogComponent, {
      data: {
        title: `Tags for ${this.contextName?? 'Unkown'}`,
        tags: this.tags,
      },
      backdropClass: 'dialog-backdrop',
    });    
  }

  ngOnDestroy(): void {
    // Disconnect observer on component destroy
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  }  
}
