import {
  Component, ElementRef, OnInit, ViewChild 
} from '@angular/core';
import { Router } from '@angular/router';
import { faPlus, faCheckSquare, faPlayCircle } from '@fortawesome/free-solid-svg-icons';
import { CsaAuthService } from 'src/app/auth/csa-auth.service';
import { LoaderService } from 'src/app/common-services/loader.service';
import { User } from 'src/app/models/user';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import * as moment from 'moment';
import { FeatureAccessService } from 'src/app/common-services/feature-access.service';
import { getUserLocationsQueryString } from 'src/app/utils/user-roles/get-location-query-string';
import { SurveysService } from '../surveys.service';

@Component({
  selector: 'app-once-off-surveys',
  templateUrl: './once-off-surveys.component.html',
  styleUrls: ['./once-off-surveys.component.scss']
})
export class OnceOffSurveysComponent implements OnInit {
  @ViewChild(MatTabGroup) tabGroup: MatTabGroup;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  
  user: User = {};

  filter: string = '';

  hasNext: string;

  index: number = 0;

  pageIndex: number = 0;

  pageSize: number = 10;

  surveyGroups: string[] = [];

  defaultTab = '';

  surveysToShow: any[] = [];

  tableColumns: string[];

  totalCount: number = 0;

  isLoading: boolean = false;

  faPlus = faPlus;

  faCheckSquare = faCheckSquare;

  faPlayCircle = faPlayCircle;

  allTemplates: any[] = [];

  date = moment();

  status = '';

  type = '';

  constructor(
    private elementRef: ElementRef,
    private surveyCompletionData: LoaderService,
    private csaAuth: CsaAuthService,
    private router: Router,
    private surveyService: SurveysService,
    private featureAccessService: FeatureAccessService, 
  ) { }

  ngOnInit(): void {
    this.user = this.csaAuth.user;
    this.formatTabs();
    this.setupTabOutput(this.defaultTab);
    this.getSurveysToShow(true);
  }

  tabChanged(tabChangeEvent: MatTabChangeEvent) {
    this.paginator.pageIndex = 0;
    this.pageIndex = 0;
    this.index = 0;
    this.pageSize = this.paginator.pageSize;
    this.setupTabOutput(tabChangeEvent.tab.textLabel);
    this.getSurveysToShow();
  }

  getNext(event: PageEvent) {
    this.index = event.pageIndex * event.pageSize;
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.getSurveysToShow();
  }

  formatTabs() {
    this.defaultTab = '';
    this.surveyGroups = [];
    if (this.featureAccessService.hasAccess('onceOffAssessments.viewAllActive')) {
      this.surveyGroups.push('All Active Assessments');
    }
    if (this.featureAccessService.hasAccess('onceOffAssessments.viewAllUpcoming')) {
      this.surveyGroups.push('All Upcoming Assessments');
      this.defaultTab = 'All Active Assessments';
    }
    if (this.featureAccessService.hasAccess('onceOffAssessments.viewAllClosed')) {
      this.surveyGroups.push('All Closed Assessments');
    }
    if (this.featureAccessService.hasAccess('onceOffAssessments.viewAll')) {
      this.surveyGroups.push('All Assessments');
    }
    if (this.featureAccessService.hasAccess('onceOffAssessments.viewAllAssignedAndActive')) {
      this.surveyGroups.push('Assigned Assessments - Active');
    }
    if (this.featureAccessService.hasAccess('onceOffAssessments.viewAllAssignedAndClosed')) {
      this.surveyGroups.push('Assigned Assessments - Closed');
    }
    if (this.featureAccessService.hasAccess('onceOffAssessments.viewAssignedResponses')) {
      this.surveyGroups.push('My Responses - Unopened');
    }
    if (this.featureAccessService.hasAccess('onceOffAssessments.viewAssignedResponses')) {
      this.surveyGroups.push('My Responses - Started');
    }
    if (this.featureAccessService.hasAccess('onceOffAssessments.viewAssignedResponses')) {
      this.surveyGroups.push('My Responses - Submitted');
    }
    if (this.featureAccessService.hasAccess('onceOffAssessments.viewAssignedResponses')) {
      this.surveyGroups.push('My Responses - Closed');
    }
    if (this.featureAccessService.hasAccess('onceOffAssessments.viewAssignedResponses')) {
      this.surveyGroups.push('My Responses - All');
    }
    this.defaultTab = this.surveyGroups ? this.surveyGroups[0] : '';
  }

  setupTabOutput(tabLabel: string) {
    switch (tabLabel) {
      case 'All Active Assessments':
        this.tableColumns = ['survey', 'dueDate', 'percentComplete', 'actions'];
        this.status = 'published';
        this.type = 'admin';
        break;
      case 'All Upcoming Assessments':
        this.tableColumns = ['survey', 'publishDate', 'templateUsed', 'questions'];
        this.status = 'active';        
        this.type = 'admin';
        break;
      case 'All Closed Assessments':
        this.tableColumns = ['survey', 'dueDate', 'percentComplete', 'actions'];
        this.status = 'closed';        
        this.type = 'admin';
        break;
      case 'All Assessments':
        this.tableColumns = ['survey', 'publishDate', 'dueDate', 'templateUsed'];
        this.status = 'closed';        
        this.type = 'admin';
        break;
      case 'Assigned Assessments - Active':
        this.tableColumns = ['survey', 'dueDate', 'percentComplete', 'actions'];
        this.status = 'published';        
        this.type = 'aboveStore';
        break;
      case 'Assigned Assessments - Closed':
        this.tableColumns = ['survey', 'dueDate', 'percentComplete', 'actions'];
        this.status = 'closed';        
        this.type = 'aboveStore';
        break;
      case 'My Responses - Unopened':
        this.tableColumns = ['survey', 'percentComplete', 'status'];
        this.status = 'active';        
        this.type = 'store';
        break;
      case 'My Responses - Started':
        this.tableColumns = ['survey', 'percentComplete', 'status'];
        this.status = 'started';        
        this.type = 'store';
        break;
      case 'My Responses - Submitted':
        this.tableColumns = ['survey', 'percentComplete', 'status'];
        this.status = 'submitted';        
        this.type = 'store';
        break;
      case 'My Responses - Closed':
        this.tableColumns = ['survey', 'percentComplete', 'status'];
        this.status = 'closed';        
        this.type = 'store';
        break;
      case 'My Responses - All':
        this.tableColumns = ['survey', 'percentComplete', 'status'];
        this.status = '';        
        this.type = 'store';
        break;
    }
  }

  fetchAdminSurveys() {
    this.surveyService
      .getSurveys(
        '/admin',
        this.user['division'],
        this.status,
        `/${this.index}`,
        `/${this.pageSize}`
      )
      .subscribe((surveysData) => {
        this.surveysToShow = surveysData['surveys'];
        this.hasNext = surveysData['has_next'];
        for (let i = 0; i < this.surveysToShow.length; i++) {
          this.surveysToShow[i].template = '';
          for (let j = 0; j < this.allTemplates.length; j++) {
            if (this.allTemplates[j].id == this.surveysToShow[i].template_id) {
              this.surveysToShow[i].template = this.allTemplates[j].title;
            }
          }
        }
        this.getPagination();
        this.isLoading = false;
      }, (error) => {
        this.isLoading = false;
      });      
  }

  fetchAboveStoreSurveys() {
    this.filter = getUserLocationsQueryString(this.csaAuth.user);
    this.surveyService
      .getSurveys(
        '/above-store',
        `${this.user['division']}&status=${this.status}${this.filter}`,
        '',
        `/${this.index}`,
        `/${this.pageSize}`
      )
      .subscribe((surveysData) => {
        this.surveysToShow = surveysData['surveys'];
        this.hasNext = surveysData['has_next'];
        this.getPagination();
      });         
  }

  fetchStoreSurveys() {
    this.filter = getUserLocationsQueryString(this.csaAuth.user);
    this.surveyService
      .getStoreSurveys(
        `?status=${this.status}${this.filter}`,
        `/${this.index}`,
        `/${this.pageSize}`
      )
      .subscribe((surveysData) => {
        this.surveysToShow = surveysData['surveys'];
        this.hasNext = surveysData['has_next'];
        this.getDueDates(this.surveysToShow);
        this.getSurveyStates(this.surveysToShow);
        this.getPagination();
        this.isLoading = false;
      }, (error) => {
        this.isLoading = false;
      });          
  }  

  getSurveysToShow(isInitial = false) {
    setTimeout(() => {
      this.isLoading = true;
      this.surveysToShow = [];
    }, 0);

    switch (this.type) {
      case 'admin':
        if (isInitial) {
          this.surveyService
            .getTemplates(
              `/list/${this.user['division']}?status=archived&status=active`
            )
            .subscribe((templateData) => {
              this.allTemplates = templateData['templates'];
              this.fetchAdminSurveys();
            });
        } else {
          this.fetchAdminSurveys();
        }     
        break;
      case 'aboveStore':
        this.fetchAboveStoreSurveys();
        break;
      case 'store':
        this.fetchStoreSurveys();
        break;
    }
  }

  getSurveyStates(surveys) {
    for (let i = 0; i < surveys.length; i++) {
      const status = surveys[i].status;
      if (status == 'active') {
        surveys[i].surveyState = 'Start';
      } else if (status == 'started') {
        surveys[i].surveyState = 'Continue';
      } else if (status == 'submitted') {
        surveys[i].surveyState = 'Submitted';
      } else if (status == 'closed') {
        surveys[i].surveyState = 'Closed';
      }
    }
  }

  getDueDates(surveys) {
    for (let i = 0; i < surveys.length; i++) {
      const surveyDueDate = surveys[i].dueDate;
      const m = moment(surveyDueDate, [
        'DD/MM/YYYY',
        'DD MMM YYYY',
        moment.ISO_8601,
      ]);
      const currentDate = moment();
      const isDueInWeek = currentDate.diff(m, 'days') >= -7 && currentDate.diff(m, 'days') <= 0;

      surveys[i].isDueInWeek = isDueInWeek;
    }
  }

  getPagination() {
    const label = this.elementRef.nativeElement.querySelector(
      '.mat-paginator-range-label'
    );
    if (label) {
      if (this.surveysToShow.length == 0) {
        label.innerHTML = 'No results to show';
        this.totalCount = 0;
      } else {
        const upToCount = this.index + this.surveysToShow.length;

        if (this.hasNext == 'Y') {
          label.innerHTML = `${String(this.index + 1)} to ${upToCount} of Many`;
          this.totalCount = upToCount + 1;
        } else {
          label.innerHTML = `${String(this.index + 1)} to ${upToCount} of ${upToCount}`;
          this.totalCount = upToCount;
        }
      }
    }
  }

  startSurvey(surveyID, event) {
    event.stopPropagation();
    if (this.type === 'store') {
      this.router.navigate(['/store-landing/store-survey', surveyID]);
    }
  }

  surveyDetails(survey: object) {
    switch (this.type) {
      case 'admin':
        this.surveyCompletionData.changeMessage(survey['avgCompletionRate']);
        this.router.navigate(['/admin-landing/once-off-survey-details', survey['id']]);   
        break;
      case 'aboveStore':
        this.surveyCompletionData.changeMessage(survey['avgCompletionRate']);
        this.router.navigate(['/above-store-landing/once-off-survey-details', survey['id']]);   
        break;
      case 'store':
        this.router.navigate(['/store-landing/survey-details', survey['id']]);   
        break;
    }
  }
}
