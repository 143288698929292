import {
  Component, OnInit, ViewChild, ViewChildren, ElementRef, QueryList,
  ViewEncapsulation
} from '@angular/core';
import * as moment from 'moment';
import ChartsEmbedSDK from '@mongodb-js/charts-embed-dom';
import {
  faRedoAlt,
  faSortDown,
  faTimes,
  faCheck
} from '@fortawesome/free-solid-svg-icons';
import { MatDialog } from '@angular/material/dialog';
import { AdhocSurveyService } from 'src/app/adhoc-surveys/adhoc-survey.service';
import { RecurringSurveyService } from 'src/app/recurring-survey/recurring-survey.service';
import { reassignArrayPropertyValues } from 'src/app/utils/data';
import { CsaBackEndService } from '../../common-services/csa-back-end.service';
import { CsaAuthService } from '../../auth/csa-auth.service';

import { MessagesComponent } from '../messages/messages.component';
import { LoaderService } from '../../common-services/loader.service';
import { User } from '../../models/user';
import { SurveysService } from '../surveys.service';

@Component({
  selector: 'app-admin-reports',
  templateUrl: './admin-reports.component.html',
  styleUrls: ['./admin-reports.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AdminReportsComponent implements OnInit {
  user: User;

  faSortDown = faSortDown;

  faCheck = faCheck;

  faRedoAlt = faRedoAlt;

  selectedAssessment = ['All Assessments'];

  selectedState = 'All States';

  selectedZone = 'All Zones';

  selectedGroupID = 'All Groups';

  selectedRegion = 'All Regions';

  selectedArea = 'All Areas';

  availableStates = [];

  availableZones = [];

  availableGroups = [];

  availableRegions = [];

  availableAreas = [];

  currentLevel = 'division';

  fullFilter = {};

  finalFilter = {};

  orgStructure;

  showZone = false;

  showGroup = false;

  showState = false;

  showRegion = false;

  showArea = false;

  faTimes = faTimes;

  storeDetails = [];

  storeDetailsFetched = false;

  storeNumber = '';

  storeName = '';

  storeFilter = {};

  noEntries = '';

  storeNameNumber = '';

  storesReturned = [];

  activeSurveys = [];

  closedSurveys = [];

  reportsList = [];

  token = '';

  searching = false;

  selectedStore = { Store_No: '', Location_Name: 'All Stores' };

  compState = (val1, val2) => val1 == val2;

  @ViewChild('selectAssessment') selectAssessment;

  // ######################Legacy Code###############
  // user;
  // date = moment();
  // dateFormatted = moment().format();
  // reportsList = [];
  // token = '';
  // level;
  // selectedState = 'All States';
  //
  // availableLevels = [];
  // availableStates = [];
  //
  //
  // faSortDown = faSortDown;

  adhocSurveys = [];

  adhocSurveys_eitb = [];

  recurringSurveys = [];

  closedAdhocSurveys = [];

  closedAdhocSurveys_eitb = [];

  closedRecurringSurveys = [];

  searchTimer;

  constructor(
    private elementRef: ElementRef,
    private dialog: MatDialog,
    private csaAuth: CsaAuthService,
    private csaBackEnd: CsaBackEndService,
    private loader: LoaderService,
    private surveyService: SurveysService,
    private adhocService: AdhocSurveyService,
    private recurringService: RecurringSurveyService
  ) { }

  ngOnInit() {
    console.log('v1');
    this.user = this.csaAuth.user;

    this.loader.show('');

    let eitbFilter = '';
    if (this.user['role'] == 'StoreLossManager') {
      eitbFilter = '&filterName=eitb';
    }

    if (this.user['domain'] != 'Bigw') {
      this.adhocService.getOnTheFlySurveytemplates('', this.user['division'], `published${eitbFilter}`, '/0', '/100', 'adhoc').subscribe((surveysData) => {
        if (this.user['division'] != 'DC') {
          if (surveysData['surveys'].length > 0) {
            // Reassign filterName property value from 'eitb' to 'gps'
            surveysData['surveys'] = reassignArrayPropertyValues(surveysData['surveys'], 'filterName', 'eitb', 'gps');                 
            this.getSurveysForLevel();
          }
        }

        if (eitbFilter) {
          this.adhocSurveys_eitb = surveysData['surveys'].sort((a, b) => a.title.localeCompare(b.title));
        } else {
          this.adhocSurveys = surveysData['surveys'].sort((a, b) => a.title.localeCompare(b.title));
        }
      });

      this.adhocService.getOnTheFlySurveytemplates('', this.user['division'], `closed${eitbFilter}`, '/0', '/100', 'adhoc').subscribe((surveysData) => {
        // Reassign filterName property value from 'eitb' to 'gps'
        surveysData['surveys'] = reassignArrayPropertyValues(surveysData['surveys'], 'filterName', 'eitb', 'gps');           
        if (eitbFilter) {
          this.closedAdhocSurveys_eitb = surveysData['surveys'].sort((a, b) => a.title.localeCompare(b.title));
        } else {
          this.closedAdhocSurveys = surveysData['surveys'].sort((a, b) => a.title.localeCompare(b.title));
        }
      });
    }

    if (!eitbFilter) {
      this.surveyService.getSurveys('/admin', this.user['division'], `published${eitbFilter}`, '/0', '/100').subscribe((surveysData) => {
        this.surveyService.getSurveys('/admin', this.user['division'], `closed${eitbFilter}`, '/0', '/100').subscribe((closedSurveysData) => {
          if (this.user['division'] != 'DC') {
            this.getSurveysForLevel();
          }
          this.closedSurveys = closedSurveysData['surveys'].sort((a, b) => a.title.localeCompare(b.title));
        });

        this.activeSurveys = surveysData['surveys'].sort((a, b) => a.title.localeCompare(b.title));
      });

      this.recurringService.getRecurringSurveys(this.user['division'], `published${eitbFilter}`, '/0', '/100', 'recurring').subscribe((surveysData) => {
        this.recurringSurveys = surveysData['surveys'].sort((a, b) => a.title.localeCompare(b.title));
      });

      this.recurringService.getRecurringSurveys(this.user['division'], `closed${eitbFilter}`, '/0', '/100', 'recurring').subscribe((surveysData) => {
        this.closedRecurringSurveys = surveysData['surveys'].sort((a, b) => a.title.localeCompare(b.title));
      });
    }
  }

  // resetFilters(): void {
  //   this.selectedAssessment = ["All Assessments"];
  //   this.selectedState = 'All States';
  //   this.selectedZone = 'All Zones';
  //   this.selectedGroupID = 'All Groups';
  //   this.selectedRegion = 'All Regions';
  //   this.selectedArea = 'All Areas';
  //   this.currentLevel = "division";
  //   this.setStore({Store_No: '', Location_Name: 'All Stores'});
  // }

  // showReset(): boolean {
  //   if (this.selectedStore["Location_Name"] != "All Stores") return true;
  //   if (
  //     this.selectedAssessment != ['All Assessments'] ||
  //     this.selectedState != 'All States' ||
  //     this.selectedZone != 'All Zones' ||
  //     this.selectedGroupID != 'All Groups' ||
  //     this.selectedRegion != 'All Regions' ||
  //     this.selectedArea != 'All Areas'
  //   )
  //     return true;
  //   return false;
  // }

  getSurveysForLevel() {
    this.surveyService.getOrgStructure(this.user['division']).subscribe((OrgStructure) => {
      this.orgStructure = OrgStructure;
      if (this.user['orgStruct']['division'] == 'state') {
        this.showState = true;
      } else {
        this.showZone = true;
      }

      if (this.user['role'] == 'GroupManager') {
        this.selectedState = this.user['state'];
        this.selectedZone = this.user['zone'];
        this.selectedGroupID = this.user['groupNo'];
        this.currentLevel = 'group';
        if (this.user['orgStruct']['division'] == 'state') {
          this.getDataToShow({ state: this.user['state'], zone: this.user['zone'], groupID: this.user['groupNo'] }, this.user['groupNo'], this.currentLevel);
        } else if (this.user['orgStruct']['division'] == 'zone') {
          this.getDataToShow({ zone: this.user['zone'], groupID: this.user['groupNo'] }, this.user['groupNo'], this.currentLevel);
        }
      } else if (this.user['role'] == 'OperationManager') {
        this.selectedState = this.user['state'];
        this.selectedZone = this.user['zone'];
        this.currentLevel = 'zone';
        if (this.user['orgStruct']['division'] == 'state') {
          this.getDataToShow({ state: this.user['state'], zone: this.user['zone'] }, this.user['zone'], this.currentLevel);
        } else if (this.user['orgStruct']['division'] == 'zone') {
          this.getDataToShow({ zone: this.user['zone'] }, this.user['zone'], this.currentLevel);
        }
      } else if (this.user['role'] == 'StateManager') {
        this.selectedState = this.user['state'];
        this.currentLevel = 'state';
        this.getDataToShow({ state: this.user['state'] }, this.user['state'], this.currentLevel);
      } else if (this.user['role'] == 'RegionManager') {
        this.selectedRegion = this.user['region'];
        this.currentLevel = 'region';
        this.getDataToShow({ region: this.user['region'] }, this.user['region'], this.currentLevel);
      } else if (this.user['role'] == 'AreaManager') {
        this.selectedRegion = this.user['region'];
        this.selectedArea = this.user['area'];
        this.currentLevel = 'area';
        this.getDataToShow({ region: this.user['region'], area: this.user['area'] }, this.user['area'], this.currentLevel);
      } else if (this.user['role'] == 'StoreLossManager') {
        this.selectedState = this.user['state'];
        this.currentLevel = 'state';
        this.getDataToShow({ state: this.user['state'] }, this.user['state'], this.currentLevel);
      } else {
        this.currentLevel = 'division';
        this.getDataToShow({}, '', this.currentLevel);
      }
    });
  }

  assessmentFilter(surveyTitle, survey) {
    if (this.selectedAssessment.indexOf(surveyTitle) == -1) {
      if (surveyTitle == 'All Assessments') {
        this.selectedAssessment = ['All Assessments'];
      } else if (surveyTitle == 'All CSA\'s') {
        this.selectedAssessment = ['All CSA\'s'];
      } else {
        this.selectAssessment.open();
        if (this.selectedAssessment.indexOf('All Assessments') > -1 || this.selectedAssessment.indexOf('All CSA\'s') > -1) {
          this.selectedAssessment = [surveyTitle];
        } else {
          this.selectedAssessment.push(surveyTitle);
        }
      }
    } else if (this.selectedAssessment.indexOf(surveyTitle) > -1) {
      const index = this.selectedAssessment.indexOf(surveyTitle);
      this.selectedAssessment.splice(index, 1);
    }

    if (this.selectedStore['Location_Name'] == 'All Stores') {
      if (this.currentLevel == 'state') {
        this.getDataToShow(this.fullFilter, this.selectedState, this.currentLevel);
      } else if (this.currentLevel == 'zone') {
        this.getDataToShow(this.fullFilter, this.selectedZone, this.currentLevel);
      } else if (this.currentLevel == 'group') {
        this.getDataToShow(this.fullFilter, this.selectedGroupID, this.currentLevel);
      } else if (this.currentLevel == 'region') {
        this.getDataToShow(this.fullFilter, this.selectedRegion, this.currentLevel);
      } else if (this.currentLevel == 'area') {
        this.getDataToShow(this.fullFilter, this.selectedArea, this.currentLevel);
      } else {
        this.getDataToShow('', '', this.currentLevel);
      }
    } else {
      this.setStore(this.selectedStore);
    }
  }

  getDataToShow(filter, selection, level) {
    this.selectedStore = { Store_No: '', Location_Name: 'All Stores' };
    this.showState = false;
    this.showZone = false;
    this.showGroup = false;
    this.showRegion = false;
    this.showArea = false;

    this.currentLevel = level;

    this.surveyService.getOrgStructure(this.user['division']).subscribe((OrgStructure) => {
      if (this.currentLevel == 'division' && this.user['domain'] == 'Woolworths') {
        if (this.user['orgStruct']['division'] == 'state') {
          this.showState = true;
        } else {
          this.showZone = true;
        }
        this.selectedState = 'All States';
        this.selectedZone = 'All Zones';
        this.selectedGroupID = 'All Groups';
        if (this.user['orgStruct']['division'] == 'state') {
          this.getAvailableStates();
        } else if (this.user['orgStruct']['division'] == 'zone') {
          this.getAvailableZones();
        }
      } else if (this.currentLevel == 'state') {
        this.showState = true;
        this.selectedState = selection;
        this.selectedZone = 'All Zones';
        this.selectedGroupID = 'All Groups';
        this.getAvailableStates();
        if (OrgStructure['state'] == 'zone') {
          this.showState = true;
          this.showZone = true;
          this.getAvailableStates();
          this.getAvailableZones();
        } else if (OrgStructure['state'] == 'groupID') {
          this.showState = true;
          this.showGroup = true;
          this.getAvailableStates();
          this.getAvailableGroups();
        }
      } else if (this.currentLevel == 'zone') {
        this.selectedZone = selection;
        this.selectedGroupID = 'All Groups';
        this.getAvailableStates();
        this.getAvailableZones();
        if (OrgStructure['zone'] == 'groupID') {
          if (this.user['orgStruct']['division'] == 'state') {
            this.showState = true;
          }
          this.showZone = true;
          this.showGroup = true;
          if (this.user['orgStruct']['division'] == 'state') {
            this.getAvailableStates();
          }
          this.getAvailableZones();
          this.getAvailableGroups();
        } else if (OrgStructure['zone'] == 'store') {
          if (this.user['orgStruct']['division'] == 'state') {
            this.showState = true;
          }
          this.showGroup = true;
        }
      } else if (this.currentLevel == 'group') {
        this.selectedGroupID = selection;
        if (OrgStructure['zone'] == 'groupID') {
          if (this.user['orgStruct']['division'] == 'state') {
            this.showState = true;
          }
          this.showZone = true;
          this.showGroup = true;
          if (this.user['orgStruct']['division'] == 'state') {
            this.getAvailableStates();
          }
          this.getAvailableZones();
          this.getAvailableGroups();
        } else if (OrgStructure['state'] == 'groupID') {
          this.showState = true;
          this.showGroup = true;
          if (this.user['orgStruct']['division'] == 'state') {
            this.getAvailableStates();
          }
          this.getAvailableGroups();
        }
      } else if (this.currentLevel == 'division' && this.user['domain'] == 'Bigw') {
        this.showRegion = true;
        this.selectedRegion = 'All Region';
        this.getAvailableRegions();
      } else if (this.currentLevel == 'region') {
        this.selectedRegion = selection;
        this.selectedArea = 'All Areas';
        this.showRegion = true;
        this.showArea = true;
        this.getAvailableAreas();
        this.getAvailableRegions();
      } else if (this.currentLevel == 'area') {
        this.selectedArea = selection;
        this.showArea = true;
        this.showRegion = true;
        this.getAvailableAreas();
        this.getAvailableRegions();
      }

      if (this.user['role'] == 'GroupManager') {
        this.showState = false;
        this.showZone = false;
        this.showGroup = false;
        if (this.user['orgStruct']['division'] == 'state') {
          this.selectedState = this.user['state'];
          this.selectedZone = this.user['zone'];
          this.selectedGroupID = this.user['group'];
        } else if (this.user['orgStruct']['division'] == 'zone') {
          this.selectedZone = this.user['zone'];
          this.selectedGroupID = this.user['group'];
        }
      } else if (this.user['role'] == 'OperationManager') {
        this.showState = false;
        this.showZone = false;
        this.showGroup = true;
        if (this.user['orgStruct']['division'] == 'state') {
          this.selectedState = this.user['state'];
          this.selectedZone = this.user['zone'];
        } else if (this.user['orgStruct']['division'] == 'zone') {
          this.selectedZone = this.user['zone'];
          this.selectedGroupID = this.user['group'];
        }
      } else if (this.user['role'] == 'StateManager') {
        this.showState = false;
        this.showZone = true;
        this.selectedState = this.user['state'];
      } else if (this.user['role'] == 'RegionManager') {
        this.showRegion = false;
        this.showArea = true;
        this.selectedRegion = this.user['region'];
      } else if (this.user['role'] == 'AreaManager') {
        this.showRegion = false;
        this.showArea = false;
        this.selectedRegion = this.user['region'];
        this.selectedArea = this.user['area'];
      } else if (this.user['role'] == 'StoreLossManager') {
        this.showState = false;
        this.showZone = true;
        this.selectedState = this.user['state'];
      }
    });

    if (selection == '' || selection == 'All States' || selection == 'All Zones' || selection == 'All Groups' || selection == 'All Regions' || selection == 'All Areas') {
      this.fullFilter = {};
    } else {
      this.fullFilter = filter;
    }

    const currentDate = moment();

    if (this.user['role'] == 'StoreLossManager') {
      this.generateFilteredCharts(this.fullFilter, 'StoreLossManager', this.selectedAssessment);
    } else {
      this.generateFilteredCharts(this.fullFilter, 'generic', this.selectedAssessment);
    }

    console.log(this);
  }

  getAvailableRegions() {
    this.surveyService.getNextLevel(`?division=${this.user['division']}`).subscribe((regionData) => {
      this.availableRegions = regionData['region'];
    });
  }

  getAvailableAreas() {
    this.surveyService.getNextLevel(`?division=${this.user['division']}&region=${this.selectedRegion}`).subscribe((data) => {
      this.availableAreas = data['area'];
    });
  }

  getAvailableStates() {
    this.surveyService.getNextLevel(`?division=${this.user['division']}`).subscribe((stateData) => {
      this.availableStates = stateData['state'];
    });
  }

  getAvailableZones() {
    if (this.user['orgStruct']['division'] == 'state') {
      this.surveyService.getNextLevel(`?division=${this.user['division']}&state=${this.selectedState}`).subscribe((data) => {
        this.availableZones = data['zone'];
      });
    } else if (this.user['orgStruct']['division'] == 'zone') {
      this.surveyService.getNextLevel(`?division=${this.user['division']}`).subscribe((data) => {
        this.availableZones = data['zone'];
      });
    }
  }

  getAvailableGroups() {
    if (this.showZone) {
      if (this.user['orgStruct']['division'] == 'state') {
        this.surveyService.getNextLevel(`?division=${this.user['division']}&state=${this.selectedState}&zone=${this.selectedZone}`).subscribe((data) => {
          this.availableGroups = data['groupID'];
        });
      } else if (this.user['orgStruct']['division'] == 'zone') {
        this.surveyService.getNextLevel(`?division=${this.user['division']}&zone=${this.selectedZone}`).subscribe((data) => {
          this.availableGroups = data['groupID'];
        });
      }
    } else if (this.user['orgStruct']['division'] == 'state') {
      this.surveyService.getNextLevel(`?division=${this.user['division']}&state=${this.selectedState}`).subscribe((data) => {
        this.availableGroups = data['groupID'];
      });
    } else if (this.user['orgStruct']['division'] == 'zone') {
      this.surveyService.getNextLevel(`?division=${this.user['division']}`).subscribe((data) => {
        this.availableGroups = data['groupID'];
      });
    }
  }

  searchForStores(storeId) {
    this.noEntries = 'Searching...';
    this.searching = true;

    clearTimeout(this.searchTimer);
    this.searchTimer = setTimeout(
      () => {
        if (this.user['division'] == 'DC') {
          this.noEntries = 'No matching DCs found..';
        } else {
          this.noEntries = 'No matching Stores found..';
        }
        if (storeId.trim()) {
          this.surveyService.getStoreList(storeId).subscribe((storeData) => {
            this.storesReturned = storeData;
            setTimeout(() => { this.searching = false; }, 1000);
          }, (error) => {
            this.searching = false;
            this.dialog.open(MessagesComponent, {
              data: {
                heading: 'An Error Has Occured',
                message: `An Error was returned when trying to find stores. Please email ${this.user['supportEmail']} for support`,
                closeText: 'Ok'
              },
              backdropClass: 'dialog-backdrop'
            });
          });
        } else {
          this.storesReturned = [];
          this.searching = false;
        }
      },
      1000
    );
  }

  setStore(store) {
    if (store['Location_Name'] == 'All Stores') {
      this.storeNumber = '';
      this.storeName = '';
      this.selectedStore = store;
      this.storeFilter = {};
      this.getSurveysForLevel();
      // if (this.user['domain'] == 'Bigw'){
      //   this.showRegion = true;
      // }else if(this.user['orgStruct']['division'] == 'state'){
      //   this.showState = true;
      // }

      // if (this.user['role']=='StoreLossManager'){
      //     this.generateFilteredCharts(this.fullFilter, this.user['role'], this.selectedAssessment);
      // }else{
      //   this.generateFilteredCharts(this.fullFilter, 'generic', this.selectedAssessment);
      //  }
    } else {
      this.selectedStore = store;
      this.storeNumber = store['Store_No'];
      this.storeName = store['Location_Name'];
      this.storeFilter = { storeID: this.storeNumber };
      if (this.user['role'] == 'StoreLossManager') {
        this.generateFilteredCharts(this.storeFilter, this.user['role'], this.selectedAssessment);
      } else {
        this.generateFilteredCharts(this.storeFilter, 'generic', this.selectedAssessment);
      }

      this.showState = false;
      this.showZone = false;
      this.showGroup = false;
      this.showRegion = false;
      this.showArea = false;
    }
  }

  @ViewChildren('chartContainer') chartContainers: QueryList<ElementRef>;

  generateFilteredCharts(filter, level, assessments) {
    this.surveyService.getReportsList('/0', '/20', `?role=${level}`).subscribe((reportsData) => {
      this.reportsList = reportsData['reports'];
      this.token = reportsData['token'];
      this.chartContainers.changes.subscribe(() => {
        let i = 0;
        this.chartContainers.toArray().forEach((el) => {
          let surveyFilter = {};
          let csaFilter = {};
          if (assessments.indexOf('All Assessments') > -1) {
            this.finalFilter = filter;
          } else if (assessments.indexOf('All CSA\'s') > -1) {
            if (this.reportsList[i]['type'] == 'actions') {
              csaFilter = { surveyTitle: /CSA/, division: this.user['division'] };
            } else {
              csaFilter = { title: /CSA/, division: this.user['division'] };
            }
            this.finalFilter = { ...csaFilter, ...filter };
          } else {
            if (this.reportsList[i]['type'] == 'actions') {
              surveyFilter = { surveyTitle: { $in: assessments }, division: this.user['division'] };
            } else {
              surveyFilter = { title: { $in: assessments }, division: this.user['division'] };
            }
            this.finalFilter = { ...surveyFilter, ...filter };
          }

          console.log(this.finalFilter);

          const sdk = new ChartsEmbedSDK({ baseUrl: this.reportsList[i].chartBaseURL });
          const chart = sdk.createChart({
            chartId: el.nativeElement.id,
            filter: this.finalFilter,
            getUserToken: () => this.token
          });
          chart.render(el.nativeElement);
          i++;
        });

        this.loader.hide('');
      });
    });
  }

  // ######################Legacy Code###############
  // ngOnInit(): void {
  //   this.user = this.csaAuth.user;
  //   this.level = this.csaAuth.user['role'];
  //   if (this.user['role'] == 'GroupManager'){
  //
  //   } else if (this.user['role'] == 'OperationManager'){
  //     if (this.user['division'] == 'Supermarkets'){
  //       this.availableLevels = [{id: 'OperationManager', title: 'Zone Reports'}, {id: 'GroupManager', title: 'Group Reports'}];
  //     } else {
  //       this.availableLevels = [{id: 'GroupManager', title: 'Group Reports'}];
  //     }
  //   } else if (this.user['role'] == 'StateManager'){
  //     if (this.user['division'] == 'Supermarkets'){
  //       this.availableLevels = [{id: 'StateManager', title: 'State Reports'}, {id: 'OperationManager', title: 'Zone Reports'}, {id: 'GroupManager', title: 'Group Reports'}];
  //     } else if (this.user['division'] == 'DC'){
  //       this.availableLevels = [{id: 'StateManager', title: 'State Reports'}];
  //     } else {
  //       this.availableLevels = [{id: 'OperationManager', title: 'State Reports'}, {id: 'GroupManager', title: 'Group Reports'}];
  //     }
  //
  //   } else if (this.user['role'] == 'Admin'){
  //     if (this.user['division'] == 'Supermarkets'){
  //       this.availableStates = ['NSW/ACT', 'QLD', 'VIC/TAS', 'SA/NT', 'WA'];
  //       this.availableLevels = [{id: 'Admin', title: 'National Reports'}, {id: 'StateManager', title: 'State Reports'}, {id: 'OperationManager', title: 'Zone Reports'}, {id: 'GroupManager', title: 'Group Reports'}];
  //     } else if (this.user['division'] == 'DC'){
  //       this.availableStates = ['NSW', 'QLD', 'VIC', 'TAS', 'SA', 'WA'];
  //       this.availableLevels = [{id: 'Admin', title: 'National Reports'}, {id: 'StateManager', title: 'State Reports'}];
  //     } else {
  //       this.availableStates = ['NSW/ACT', 'QLD', 'VIC'];
  //       this.availableLevels = [{id: 'Admin', title: 'National Reports'}, {id: 'OperationManager', title: 'State Reports'}, {id: 'GroupManager', title: 'Group Reports'}];
  //     }
  //   }
  //
  //   this.csaBackEnd.getReportsList('/0', '/20', '?role='+this.user['role']+'&division='+this.user['division']).subscribe(reportsData => {
  //     this.reportsList  = reportsData['reports'];
  //     this.token = reportsData['token'];
  //   });
  //
  // }
  //
  // @ViewChildren('chartContainer') chartContainers: QueryList<ElementRef>;
  //
  //
  // ngAfterViewInit(){
  //
  //   if (this.user['role'] == 'GroupManager'){
  //      if (this.user['division'] == 'Supermarkets'){
  //        let filter = {"division" : this.user['division'], 'state': this.user['state'], 'zone': this.user['zone'], 'groupID': this.user['groupNo']};
  //         this.generateFilteredCharts(filter, this.user['role']);
  //      } else if (this.user['division'] == 'DC'){
  //        let filter = {"division" : this.user['division'], 'state': this.user['state']};
  //         this.generateFilteredCharts(filter, this.user['role']);
  //      } else {
  //        let filter = {"division" : this.user['division'], 'state': this.user['state'], 'groupID': this.user['groupNo']};
  //         this.generateFilteredCharts(filter, this.user['role']);
  //      }
  //   } else if (this.user['role'] == 'OperationManager'){
  //     if (this.user['division'] == 'Supermarkets'){
  //        let filter = {"division" : this.user['division'], 'state': this.user['state'], 'zone': this.user['zone']};
  //         this.generateFilteredCharts(filter, this.user['role']);
  //      } else if (this.user['division'] == 'DC'){
  //        let filter = {"division" : this.user['division'], 'state': this.user['state']};
  //         this.generateFilteredCharts(filter, this.user['role']);
  //      } else {
  //        let filter = {"division" : this.user['division'], 'state': this.user['state']};
  //         this.generateFilteredCharts(filter, this.user['role']);
  //      }
  //   } else if (this.user['role'] == 'StateManager'){
  //     let filter = {"division" : this.user['division'], 'state': this.user['state']};
  //     this.generateFilteredCharts(filter, this.user['role']);
  //
  //   } else if (this.user['role'] == 'Admin'){
  //     this.generateCharts(this.user['role']);
  //   }
  //
  // }
  //
  // chartStateHandler(state){
  //   this.selectedState = state;
  //   if (state == 'All States'){
  //     this.generateCharts(this.level);
  //   }else{
  //     let filter = {"division" : this.user['division'], 'state': state};
  //     this.generateFilteredCharts(filter, this.level);
  //   }
  // }
  //
  // chartLevelHandler(level){
  //   this.level = level;
  //   if (this.user['role'] == 'GroupManager'){
  //      if (this.user['division'] == 'Supermarkets'){
  //        let filter = {"division" : this.user['division'], 'state': this.user['state'], 'zone': this.user['zone'], 'groupID': this.user['groupNo']};
  //         this.generateFilteredCharts(filter, level);
  //      } else {
  //        let filter = {"division" : this.user['division'], 'state': this.user['state'], 'groupID': this.user['groupNo']};
  //         this.generateFilteredCharts(filter, level);
  //      }
  //   } else if (this.user['role'] == 'OperationManager'){
  //     if (this.user['division'] == 'Supermarkets'){
  //        let filter = {"division" : this.user['division'], 'state': this.user['state'], 'zone': this.user['zone']};
  //         this.generateFilteredCharts(filter, level);
  //      } else {
  //        let filter = {"division" : this.user['division'], 'state': this.user['state']};
  //         this.generateFilteredCharts(filter, level);
  //      }
  //   } else if (this.user['role'] == 'StateManager'){
  //     let filter = {"division" : this.user['division'], 'state': this.user['state']};
  //     this.generateFilteredCharts(filter, level);
  //
  //   } else if (this.user['role'] == 'Admin'){
  //     let filter = {"division" : this.user['division']};
  //     this.generateFilteredCharts(filter, level);
  //   }
  //
  // }
  //
  // generateFilteredCharts(filter, level){
  //     this.csaBackEnd.getReportsList('/0', '/20', '?role='+level+'&division='+this.user['division']).subscribe(reportsData => {
  //       this.reportsList  = reportsData['reports'];
  //       this.token = reportsData['token'];
  //       this.chartContainers.changes.subscribe(() => {
  //         let i = 0;
  //         this.chartContainers.toArray().forEach(el => {
  //           let sdk = new ChartsEmbedSDK({baseUrl: this.reportsList[i].chartBaseURL});
  //           let chart = sdk.createChart({
  //             chartId: el.nativeElement.id,
  //             filter: filter,
  //             getUserToken: () => {
  //               return this.token;
  //             }
  //           });
  //           chart.render(el.nativeElement);
  //           i++;
  //         });
  //       });
  //     });
  // }
  //
  // generateCharts(level){
  //   this.csaBackEnd.getReportsList('/0', '/20', '?role='+level+'&division='+this.user['division']).subscribe(reportsData => {
  //     this.reportsList  = reportsData['reports'];
  //     this.token = reportsData['token'];
  //     this.chartContainers.changes.subscribe(() => {
  //       let i = 0;
  //       this.chartContainers.toArray().forEach(el => {
  //         let sdk = new ChartsEmbedSDK({baseUrl: this.reportsList[i].chartBaseURL});
  //         let chart = sdk.createChart({
  //           chartId: el.nativeElement.id,
  //           filter: {"division" : this.user['division']},
  //           getUserToken: () => {
  //             return this.token;
  //           }
  //         });
  //         chart.render(el.nativeElement);
  //         i++;
  //       });
  //     });
  //   });
  //
  //
  // }
}
