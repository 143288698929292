import {
  Component, OnInit, ElementRef, 
  OnDestroy
} from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment/moment';
import { MatDialog } from '@angular/material/dialog';
import {
  faPlus, faPencilAlt, faTimes, faSortDown, faFileAlt 
} from '@fortawesome/free-solid-svg-icons';
import { Subject, takeUntil } from 'rxjs';
import { SurveysService } from 'src/app/surveys/surveys.service';
import { AdhocSurveyService } from 'src/app/adhoc-surveys/adhoc-survey.service';
import { FeatureAccessService } from 'src/app/common-services/feature-access.service';
import { deepCopy } from 'src/app/utils/deep-copy/deep-copy';
import { User } from '../../models/user';
import { MessagesComponent } from '../../surveys/messages/messages.component';
import { CsaAuthService } from '../../auth/csa-auth.service';
import { StoreSelectionDialogComponent } from '../../surveys/store-selection-dialog/store-selection-dialog.component';

@Component({
  selector: 'app-mrr-survey-details',
  templateUrl: './mrr-survey-details.component.html',
  styleUrls: ['./mrr-survey-details.component.scss'],
})

export class MrrSurveyDetailsComponent implements OnInit, OnDestroy {
  surveyID = '';

  faPlus = faPlus;

  faTimes = faTimes;

  faSortDown = faSortDown;

  faPencilAlt = faPencilAlt;

  faFileAlt = faFileAlt;

  dateToday = new Date();

  maxStartDate;

  minStartDate = new Date();

  minEndDate;

  count = 10;

  editEndDate = false;

  editStartDate = false;

  editValidity = false;

  index = 0;

  orgStructure;

  surveyDetails = {};

  totalCount = 0;

  user: User;

  cancelOrCloseAssessment = false;

  canEditAvailableStartDate = false;

  canEditAvailableToDate = false;

  // Tags
  editTags = false;

  tagList: string[] = [];

  originalTagList: string[] = [];

  private onDestroy$ = new Subject<void>(); // used to unsubscribe from observables on component destroy

  constructor(
    private csaAuth: CsaAuthService,
    private actRoute: ActivatedRoute,
    private dialog: MatDialog,
    private router: Router,
    private surveyService : SurveysService,
    private adhocService : AdhocSurveyService,
    private featureService: FeatureAccessService, 

  ) { }

  ngOnInit(): void {
    this.surveyID = this.actRoute.snapshot.paramMap.get('id');
    this.user = this.csaAuth.user;
    this.cancelOrCloseAssessment = this.featureService.hasAccess('mrrSurveyDetails.cancelOrCloseAssessment');
    this.canEditAvailableStartDate = this.featureService.hasAccess('mrrSurveyDetails.editAvailableStartDate');
    this.canEditAvailableToDate = this.featureService.hasAccess('mrrSurveyDetails.editAvailableToDate');

    this.csaAuth.userChanges$().pipe(takeUntil(this.onDestroy$)).subscribe((user) => {
      this.user = user;
    });

    this.surveyService
      .getAdminSurveyById(this.surveyID)
      .subscribe((surveysData) => {
        this.surveyDetails = surveysData;
        this.maxStartDate = new Date(this.surveyDetails['endDate']);
        this.minEndDate = new Date(this.surveyDetails['startDate']);
        this.tagList = this.surveyDetails['tags'] ?? [];
        this.originalTagList = deepCopy(this.tagList);
        console.log(this);
      });
  }

  splitByCaps(value): string {
    if (
      this.user['division'] == 'DC'
      && (value == 'Store' || value == 'MultiStore')
    ) {
      if (value == 'Store') {
        return 'DCs';
      }
      if (value == 'MultiStore') {
        return 'Multi DC Users';
      }
    }

    if (
      value == 'Admin'
      || value == 'NationalView'
      || value == 'MultiStore'
    ) {
      value += 'Users';
    } else {
      if (value == 'OperationManager') {
        value = 'ZoneOperationManager';
      } else if (value == 'StockLossPartner') {
        value = 'StoreLossPartner';
      }
      if (value.substr(-1) != 's') {
        value += 's';
      }
    }

    return value.match(/[A-Z][a-z]+|[0-9]+/g).join(' ');
  }

  cancelTagChanges() {
    this.tagList = deepCopy(this.originalTagList);
  }

  saveTagChanges() {
    const updateData = {
      modifiedBy: this.user['email'],
      id: this.surveyDetails['id'],
      startDate: this.surveyDetails['startDate'],
      endDate: this.surveyDetails['endDate'],
      surveyValidity: Number(this.surveyDetails['surveyValidity']),
      type: 'adhoc',
      status: this.surveyDetails['status'],
      tags: this.tagList,
    };    

    this.surveyService.updateSurvey(updateData).subscribe(
      (data) => {
        this.originalTagList = deepCopy(this.tagList);
        console.log(`Tags Updated for: ${this.surveyDetails['title']}`, this.tagList);
        this.dialog.open(MessagesComponent, {
          data: {
            heading: 'Tags Updated',
            message:
              'Tags have been updated.',
            closeText: 'Ok',
          },
          backdropClass: 'dialog-backdrop',
        });
      },
      (error) => {
        this.dialog.open(MessagesComponent, {
          data: {
            heading: "Couldn't Update Tags",
            message:
              `Please email ${this.user['supportEmail']} for support`,
            closeText: 'Ok',
          },
          backdropClass: 'dialog-backdrop',
        });
      }
    );
  }

  haveTagsChanged(): boolean {
    if (this.tagList.length !== this.originalTagList.length) {
      return true;
    }

    return !this.originalTagList.every((value, index) => value === this.tagList[index]);    
  }

  tagListChanged(tagList: string[]) {
    console.log('tagListChanged', tagList);
    this.tagList = tagList;
  }

  cancelAssessment() {
    const updateData = {
      modifiedBy: this.user['email'],
      id: this.surveyDetails['id'],
      type: 'adhoc',
      status: 'closed',
    };

    const confirmationCancel = this.dialog.open(MessagesComponent, {
      data: {
        heading: 'Are You Sure?',
        message:
          `If you continue, no future '${ 
            this.surveyDetails['title'] 
          } Material Risk Review Assessment will be created for stores. Existing Material Risk Review Assessments will remain active until their due date.`,
        closeText: 'Close Assessment',
        cancelText: 'Cancel',
      },
      backdropClass: 'dialog-backdrop',
    });

    confirmationCancel.afterClosed().subscribe((result) => {
      if (result == 'logout') {
        this.surveyService.updateSurvey(updateData).subscribe(
          (message) => {
            if (message['status'] == 200) {
              const confirmationTwo = this.dialog.open(MessagesComponent, {
                data: {
                  heading: 'Material Risk Review Assessment Closed',
                  message:
                    'This Material Risk Review Assessment has now been closed. The page will now reload to update the status',
                  closeText: 'Ok',
                },
                backdropClass: 'dialog-backdrop',
              });

              confirmationTwo.afterClosed().subscribe((result) => {
                window.location.reload();
              });
            } else {
              this.dialog.open(MessagesComponent, {
                data: {
                  heading: "Couldn't Close Material Risk Review Assessment",
                  message:
                    `Please email ${ 
                      this.user['supportEmail']
                    } for support`,
                  closeText: 'Ok',
                },
                backdropClass: 'dialog-backdrop',
              });
            }
          },
          (error) => {
            this.dialog.open(MessagesComponent, {
              data: {
                heading: "Couldn't Close Material Risk Review Assessment",
                message:
                  `Please email ${this.user['supportEmail']} for support`,
                closeText: 'Ok',
              },
              backdropClass: 'dialog-backdrop',
            });
          }
        );
      }
    });
  }

  createMRR(survey) {
    if (!this.featureService.hasAccess('mrrSurveyDetails.createMRR')) {
      const adhocAssessment = {};
      adhocAssessment['storeID'] = this.user['storeID'];
      adhocAssessment['survey_id'] = this.surveyDetails['id'];

      if (this.surveyDetails['activeResponseCount'] > 0) {
        const confirmMultipleDialog = this.dialog.open(MessagesComponent, {
          data: {
            heading: 'Active Responses',
            message:
              `You currently have ${ 
                this.surveyDetails['activeResponseCount']
              } Active Responses for this Assessment.`,
            closeText: 'Cancel',
          },
          backdropClass: 'dialog-backdrop',
        });

        confirmMultipleDialog.afterClosed().subscribe((result) => {
          if (result == 'logout') {  
            this.router.navigate([
              '/mrr-survey-details',
              this.surveyDetails['id'],
            ]);
          }
        });
      } else {
        this.adhocService
          .createOnTheFlySurvey(adhocAssessment)
          .subscribe((reply) => {
            if (reply['status_message'] == 'Already created') {
              this.dialog.open(MessagesComponent, {
                data: {
                  heading: 'You Have Already Responded',
                  closeText: 'OK',
                  message:
                  'You have already created response for this store. No another response can create for this particular store.',
                },
                disableClose: true,
                backdropClass: 'dialog-backdrop',
              });
            } else if (reply['status'] == 200) {
              const dialogSuccess = this.dialog.open(MessagesComponent, {
                data: {
                  heading: 'Material Risk Review Assessment Created',
                  closeText: 'OK',
                  message:
                      'Material Risk Review Assessment has been created. Please click ok to go to the Material Risk Review Assessment, and begin adding your findings',
                },
                disableClose: true,
                backdropClass: 'dialog-backdrop',
              });

              dialogSuccess.afterClosed().subscribe((result) => {
                this.router.navigate([
                  '/mrr-response',
                  reply['response_id'],
                ]);
              });
            } else {
              this.dialog.open(MessagesComponent, {
                data: {
                  heading: "Couldn't Schedule Material Risk Review Assessment",
                  message: `Please email ${this.user['supportEmail']} for support`,
                },
                disableClose: true,
                backdropClass: 'dialog-backdrop',
              });
            }
          });
      }
    } else {
      const storeSelectDialog = this.dialog.open(StoreSelectionDialogComponent, {
        backdropClass: 'dialog-backdrop',
        minWidth: '70%',
        maxWidth: '90vw',
        height: 'auto',
        data: {
          allowClose: true,
          message: 'Please select the store you are creating this Material Risk Review Assessment for.',
          surveyTitle: survey['title'],
          sections: survey['sectionHeading'],
          startDate: survey['startDate'],
          filterName: 'mrr',
        },
      });

      storeSelectDialog.afterClosed().subscribe((result) => {
        if (result['storeNo']) {
          const adhocAssessment = {};
          adhocAssessment['storeID'] = result['storeNo'];
          adhocAssessment['survey_id'] = this.surveyDetails['id'];

          this.surveyService
            .getAboveStoreSurveyID(
              this.surveyDetails['id'],
              `?storeID=${adhocAssessment['storeID']}`
            )
            .subscribe((surveyDetails) => {
              if (surveyDetails['activeResponseCount'] > 0) {
                const confirmMultipleDialog = this.dialog.open(
                  MessagesComponent,
                  {
                    data: {
                      heading: 'Active Responses',
                      message:
                        `You currently have ${ 
                          surveyDetails['activeResponseCount']
                        } Active Responses for this Store & Assessment.`,
                      closeText: 'Cancel',
                    },
                    backdropClass: 'dialog-backdrop',
                  }
                );

                confirmMultipleDialog.afterClosed().subscribe((result) => {
                  console.log(result);

                  if (result == 'logout') {  
                    this.router.navigate([
                      '/mrr-survey-details',
                      this.surveyDetails['id'],
                    ]);
                  }
                });
              } else {
                this.adhocService
                  .createOnTheFlySurvey(adhocAssessment)
                  .subscribe((reply) => {
                    if (reply['status_message'] == 'Already created') {
                      this.dialog.open(MessagesComponent, {
                        data: {
                          heading: 'You Have Already Responded',
                          closeText: 'OK',
                          message:
                          'You have already created response for this store. No another response can create for this particular store.',
                        },
                        disableClose: true,
                        backdropClass: 'dialog-backdrop',
                      });
                    } else if (reply['status'] == 200) {
                      const dialogSuccess = this.dialog.open(MessagesComponent, {
                        data: {
                          heading: 'Material Risk Review Assessment Created',
                          closeText: 'OK',
                          message:
                              'Material Risk Review Assessment has been created. Please click ok to go to the Material Risk Review Assessment, and begin adding your findings',
                        },
                        disableClose: true,
                        backdropClass: 'dialog-backdrop',
                      });
                      dialogSuccess.afterClosed().subscribe((result) => {
                        this.router.navigate(['/mrr-response', reply['response_id']]);
                      });
                    } else {
                      this.dialog.open(MessagesComponent, {
                        data: {
                          heading: "Couldn't Schedule Material Risk Review Assessment",
                          message:
                            `Please email ${ 
                              this.user['supportEmail']
                            } for support`,
                        },
                        disableClose: true,
                        backdropClass: 'dialog-backdrop',
                      });
                    }
                  });
              }
            });
        } else {
          this.dialog.open(MessagesComponent, {
            data: {
              heading: 'Material Risk Review Assessment Cancelled',
              message:
                'You need to select a store for your Material Risk Review Assessment before starting.',
            },
            disableClose: true,
            backdropClass: 'dialog-backdrop',
          });
        }
      });
    }
  }

  getDate(date) {
    date = moment(date, ['DD/MM/YYYY', 'DD MMM YYYY', moment.ISO_8601]).format(
      'DD MMM YYYY'
    );
    return date;
  }

  submitForm2(survey, value) {
    const updateData = {
      modifiedBy: this.user['email'],
      id: this.surveyDetails['id'],
      endDate: moment(value.dueDate, [
        'DD/MM/YYYY',
        'DD MMM YYYY',
        moment.ISO_8601,
      ]).format(),
      surveyValidity: Number(this.surveyDetails['surveyValidity']),
      type: 'adhoc',
      status: this.surveyDetails['status'],
    };

    this.surveyService.updateSurvey(updateData).subscribe(
      (message) => {
        if (message['status'] == 200) {
          const confirmationTwo = this.dialog.open(MessagesComponent, {
            data: {
              heading: 'End Date Updated',
              message:
                'The end date has been updated. The page will now reload to update the reminder dates',
              closeText: 'Ok',
            },
            backdropClass: 'dialog-backdrop',
          });

          confirmationTwo.afterClosed().subscribe((result) => {
            window.location.reload();
          });
        } else {
          this.dialog.open(MessagesComponent, {
            data: {
              heading: "Couldn't Update End Date",
              message:
                `Please email ${this.user['supportEmail']} for support`,
              closeText: 'Ok',
            },
            backdropClass: 'dialog-backdrop',
          });
        }
      },
      (error) => {
        this.dialog.open(MessagesComponent, {
          data: {
            heading: "Couldn't Update End Date",
            message:
              `Please email ${this.user['supportEmail']} for support`,
            closeText: 'Ok',
          },
          backdropClass: 'dialog-backdrop',
        });
      }
    );

    this.surveyDetails['dueDate'] = this.getDate(value.dueDate);

    this.editEndDate = false;
    this.maxStartDate = moment(value.dueDate, [
      'DD/MM/YYYY',
      'DD MMM YYYY',
      moment.ISO_8601,
    ]);
    survey.reset();
  }

  submitForm3(survey, value) {
    const updateData = {
      modifiedBy: this.user['email'],
      id: this.surveyDetails['id'],
      startDate: moment(value.publishDate, [
        'DD/MM/YYYY',
        'DD MMM YYYY',
        moment.ISO_8601,
      ]).format(),
      surveyValidity: Number(this.surveyDetails['surveyValidity']),
      type: 'adhoc',
      status: this.surveyDetails['status'],
    };

    this.surveyService.updateSurvey(updateData).subscribe(
      (message) => {
        if (message['status'] == 200) {
          const confirmation = this.dialog.open(MessagesComponent, {
            data: {
              heading: 'Start Date Updated',
              message:
                'The start date has been updated. The page will now reload to update the Assessment Schedule',
              closeText: 'Ok',
            },
            backdropClass: 'dialog-backdrop',
          });

          confirmation.afterClosed().subscribe((result) => {
            window.location.reload();
          });
        } else {
          this.dialog.open(MessagesComponent, {
            data: {
              heading: "Couldn't Update Start Date",
              message:
                `Please email ${this.user['supportEmail']} for support`,
              closeText: 'Ok',
            },
            backdropClass: 'dialog-backdrop',
          });
        }
      },
      (error) => {
        this.dialog.open(MessagesComponent, {
          data: {
            heading: "Couldn't Update Start Date",
            message:
              `Please email ${this.user['supportEmail']} for support`,
            closeText: 'Ok',
          },
          backdropClass: 'dialog-backdrop',
        });
      }
    );

    this.minEndDate = moment(value.publishDate, [
      'DD/MM/YYYY',
      'DD MMM YYYY',
      moment.ISO_8601,
    ]);
    this.editStartDate = false;
    this.surveyDetails['publishDate'] = this.getDate(value.publishDate);

    survey.reset();
  }

  submitForm4(survey, value) {
    const updateData = {
      modifiedBy: this.user['email'],
      id: this.surveyDetails['id'],
      startDate: this.surveyDetails['startDate'],
      endDate: this.surveyDetails['endDate'],
      surveyValidity: Number(value['validity']),
      type: 'adhoc',
      status: this.surveyDetails['status'],
    };

    this.surveyService.updateSurvey(updateData).subscribe(
      (message) => {
        if (message['status'] == 200) {
          const confirmation = this.dialog.open(MessagesComponent, {
            data: {
              heading: 'Days to Complete Updated',
              message:
                'The Days to Complete have been updated. The page will now reload to update the Assessment Schedule',
              closeText: 'Ok',
            },
            backdropClass: 'dialog-backdrop',
          });

          confirmation.afterClosed().subscribe((result) => {
            window.location.reload();
          });
        } else {
          this.dialog.open(MessagesComponent, {
            data: {
              heading: "Couldn't Update Days to Complete",
              message:
                `Please email ${this.user['supportEmail']} for support`,
              closeText: 'Ok',
            },
            backdropClass: 'dialog-backdrop',
          });
        }
      },
      (error) => {
        this.dialog.open(MessagesComponent, {
          data: {
            heading: "Couldn't Update Days to Complete",
            message:
              `Please email ${this.user['supportEmail']} for support`,
            closeText: 'Ok',
          },
          backdropClass: 'dialog-backdrop',
        });
      }
    );

    this.minEndDate = moment(value.publishDate, [
      'DD/MM/YYYY',
      'DD MMM YYYY',
      moment.ISO_8601,
    ]);
    this.editValidity = false;
    this.surveyDetails['publishDate'] = this.getDate(value.publishDate);

    survey.reset();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
