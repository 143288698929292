<div class="page-container">
  <h1>{{surveyDetails['title'] | unescape}}</h1>

  <div class="question-options-row">
    <div class="filter-chips question-options">
      <div class="chipDrop">
        <mat-chip-list aria-label="Filters">
          <mat-chip (click)="cancelAssessment()" *ngIf="('recurringAssessments.cancelOrClose' | accessCheck) && (surveyDetails['status'] === 'active' || surveyDetails['status'] === 'published')" color="primary" selected>
            <fa-icon class="chip-left-icon" [icon]="faTimes"></fa-icon>
            <span *ngIf="surveyDetails['status'] === 'active'">
              Cancel Assessment
            </span>
            <span *ngIf="surveyDetails['status'] === 'published'">
              Close Assessment
            </span>
          </mat-chip>
          <mat-chip *ngIf="'templates.view' | accessCheck" [routerLink]="['/template-details', surveyDetails['template_id']]" color="primary" selected>
            <fa-icon class="chip-left-icon" [icon]="faFileAlt"></fa-icon>
            <span>See Template</span>
          </mat-chip>
        </mat-chip-list>
      </div>
    </div>
  </div>

  <h2 class="left-align">Recurring Assessment Details:</h2>

  <div class="audit-tool-cards-container">
    <div class="p2 audit-tool-card list-screen-card">
      <div class="question-options-row no-bottom-margin">
        <div class="survey-detail-block" *ngIf="surveyDetails['status'] !== 'closed'">
          <h4>Current Completion:</h4>
          <h4 class="light-green medium-text" *ngIf="surveyDetails['status'] === 'active'">Yet To Be Published</h4>
          <h1 *ngIf="surveyDetails['status'] !== 'active' && surveyDetails['avgCompletionRate'] || surveyDetails['avgCompletionRate'] == 0">{{ surveyDetails['avgCompletionRate'] | percent}}</h1>
          <p *ngIf="surveyDetails['status'] !== 'active' && !surveyDetails['avgCompletionRate'] && surveyDetails['avgCompletionRate'] != 0">No Active Assessment</p>
        </div>
        <div class="survey-detail-block">
          <h4>Frequency:</h4>
          <p>{{surveyDetails['frequency'] | titlecase}}</p>
        </div>
        <div class="survey-detail-block">
          <h4>Emails Enabled:</h4>
          <p *ngIf="surveyDetails['sendEmail'] === 'Y'">Yes</p>
          <p *ngIf="surveyDetails['sendEmail'] === 'N'">No</p>
        </div>
        <div class="survey-detail-block">
          <h4>Days Published:</h4>
          <p *ngIf="!surveyDetails['daysOfWeek'] && surveyDetails['frequency'] === 'daily'">Every Day</p>
          <p *ngIf="surveyDetails['frequency'] === 'monthly'">
            <span *ngFor="let day of surveyDetails['daysOfMonth']; let k=index">
              <span *ngIf="surveyDetails['daysOfMonth'][k] == 1">{{surveyDetails['daysOfMonth'][k]}}st<span *ngIf="surveyDetails['daysOfMonth']?.length - 1 > k">, </span></span>
              <span *ngIf="surveyDetails['daysOfMonth'][k] == 2">{{surveyDetails['daysOfMonth'][k]}}nd<span *ngIf="surveyDetails['daysOfMonth']?.length - 1 > k">, </span></span>
              <span *ngIf="surveyDetails['daysOfMonth'][k] == 3">{{surveyDetails['daysOfMonth'][k]}}rd<span *ngIf="surveyDetails['daysOfMonth']?.length - 1 > k">, </span></span>
              <span *ngIf="surveyDetails['daysOfMonth'][k] == 4">{{surveyDetails['daysOfMonth'][k]}}th<span *ngIf="surveyDetails['daysOfMonth']?.length - 1 > k">, </span></span>
            </span>
          </p>
          <p *ngIf="surveyDetails['daysOfWeek']" >
            <span *ngFor="let day of surveyDetails['daysOfWeek']; let j=index">
              <span *ngIf="surveyDetails['daysOfWeek'][j] == 0">Monday<span *ngIf="surveyDetails['daysOfWeek']?.length - 1 > j">, </span></span>
              <span *ngIf="surveyDetails['daysOfWeek'][j] == 1">Tuesday<span *ngIf="surveyDetails['daysOfWeek']?.length - 1 > j">, </span></span>
              <span *ngIf="surveyDetails['daysOfWeek'][j] == 2">Wednesday<span *ngIf="surveyDetails['daysOfWeek']?.length - 1 > j">, </span></span>
              <span *ngIf="surveyDetails['daysOfWeek'][j] == 3">Thursday<span *ngIf="surveyDetails['daysOfWeek']?.length - 1 > j">, </span></span>
              <span *ngIf="surveyDetails['daysOfWeek'][j] == 4">Friday<span *ngIf="surveyDetails['daysOfWeek']?.length - 1 > j">, </span></span>
              <span *ngIf="surveyDetails['daysOfWeek'][j] == 5">Saturday<span *ngIf="surveyDetails['daysOfWeek']?.length - 1 > j">, </span></span>
              <span *ngIf="surveyDetails['daysOfWeek'][j] == 6">Sunday<span *ngIf="surveyDetails['daysOfWeek']?.length - 1 > j">, </span></span>
            </span>
          </p>
        </div>
      </div>

      <div class="question-options-row no-top-margin no-bottom-margin">
        <div class="survey-detail-block" *ngIf="surveyDetails['status'] !== 'closed'">
          <h4>First Publish Date:</h4>
          <p *ngIf="!editStartDate">  {{ surveyDetails['startDate']| date :'dd MMM y'  }}<a *ngIf="(dateToday < minEndDate) && ('recurringAssessments.edit' | accessCheck)" class="med-green" (click)="editStartDate = true; editEndDate = false; editValidity = false;"><fa-icon [icon]="faPencilAlt"></fa-icon></a></p>
          <form *ngIf="editStartDate" class="wide-form" #publishDateForm="ngForm" (submit)="submitForm3(publishDateForm, publishDateForm.value)">
            <mat-form-field class="right-margin date-field">
              <mat-label>Start Date</mat-label>
              <input 
                (keydown)="false" 
                name="publishDate" 
                [matDatepickerFilter]="dayFilter" 
                (click)="publishPicker.open()" 
                [max]="maxStartDate" 
                [min]="minStartDate" 
                ngModel matInput 
                [matDatepicker]="publishPicker" 
                required>
              <mat-datepicker-toggle matSuffix [for]="publishPicker"></mat-datepicker-toggle>
              <mat-datepicker #publishPicker></mat-datepicker>
              <mat-error>Date after today and before selected End Date</mat-error>
            </mat-form-field>
            <button [disabled]="!publishDateForm.valid" class="csa-button-solid survey-submit" type="submit">Save</button>
            <button class="csa-button-outline" (click)="editStartDate = false;">Cancel</button>
          </form>
        </div>
        <div class="survey-detail-block" *ngIf="surveyDetails['status'] !== 'closed'">
          <h4>Next Publish Date:</h4>
          <p>{{ surveyDetails['nextPublishDate']| date :'dd MMM y'  }} </p>
        </div>
        <div class="survey-detail-block" *ngIf="surveyDetails['status'] !== 'closed'">
          <h4>Last Publish Date:</h4>
          <p *ngIf="!editEndDate">{{ surveyDetails['endDate']| date :'dd MMM y'  }}  <a class="med-green" *ngIf="(dateToday < minEndDate) && ('recurringAssessments.edit' | accessCheck)" (click)="editEndDate = true; editStartDate = false; editValidity = false;"><fa-icon [icon]="faPencilAlt"></fa-icon></a></p>
          <form *ngIf="editEndDate" class="wide-form" #endDateForm="ngForm" (submit)="submitForm2(endDateForm, endDateForm.value)">
            <mat-form-field class="right-margin date-field">
              <mat-label>End Date</mat-label>
              <input 
                (keydown)="false" 
                name="dueDate" 
                [matDatepickerFilter]="dayFilter" 
                (click)="picker.open()" 
                [min]="minEndDate" 
                ngModel 
                matInput 
                [matDatepicker]="picker" 
                required>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
              <mat-error>Please select a date after the Start Date</mat-error>
            </mat-form-field>
            <button [disabled]="!endDateForm.valid" class="csa-button-solid survey-submit" type="submit">Save</button>
            <button class="csa-button-outline" (click)="editEndDate = false;">Cancel</button>
          </form>
        </div>
        <div class="survey-detail-block">
          <h4>Days to Complete:</h4>
          <p *ngIf="!editValidity">{{ surveyDetails['surveyValidity']}} <a *ngIf="(dateToday < minEndDate) && ('recurringAssessments.edit' | accessCheck)" class="med-green" (click)="editValidity = true; editEndDate = false; editStartDate = false;"><fa-icon [icon]="faPencilAlt" *ngIf="surveyDetails['frequency'] === 'weekly'"></fa-icon></a></p>
          <form *ngIf="editValidity" class="wide-form" #validityForm="ngForm" (submit)="submitForm4(validityForm, validityForm.value)">
            <div class="right-margin date-field">
              <div class="switch-container">
                5 days &nbsp; <mat-slide-toggle color="primary" #validity ngModel name="validity"></mat-slide-toggle> &nbsp; 7 days
              </div>
            </div>
            <button class="csa-button-solid survey-submit" type="submit">Save</button>
            <button class="csa-button-outline" (click)="editValidity = false;">Cancel</button>
          </form>
        </div>
      </div>

      <div class="question-options-row no-top-margin" *ngIf="'tags.view' | accessCheck">
        <div class="survey-detail-block survey-detail-block-large">
          <h4 class="tags-heading">Tags:</h4>
          <div class="tags-container">
            <div *ngIf="!editTags">
              <div class="search-tags">
                <button 
                  *ngFor="let tag of tagList"
                  tabindex="-1"
                  class="chip-input"
                >
                  {{ tag }}
                </button>
                <p *ngIf="'tags.edit' | accessCheck" class="tag-edit-pencil-container">
                  <a (click)="editTags = true" class="med-green">
                    <fa-icon [icon]="faPencilAlt"></fa-icon>
                  </a>
                </p>           
              </div>
            </div>
          </div>
          <div
            *ngIf="editTags"
            class="wide-form"
          >
            <app-tags-autocomplete-dropdown
              [tagList]="tagList"
              (tagListChanged)="tagListChanged($event)"
            >
            </app-tags-autocomplete-dropdown>
            <button
              class="csa-button-solid survey-submit"
              [disabled]="!haveTagsChanged()"
              (click)="editTags = false; saveTagChanges()"
            >
              Save
            </button>
            <button
              class="csa-button-outline"
              (click)="editTags = false; cancelTagChanges()"
            >
              Cancel
            </button>
          </div>
        </div>        
      </div>      

      <div *ngIf="surveyDetails['status'] === 'closed'">
        <span class="medium-text orange-text">This Assessment is Closed.</span>
      </div>
    </div>
  </div>
  
  <app-results-table [surveyType]="'recurring'" *ngIf="surveyDetails['status'] !== 'active' && surveyDetails['status'] !== 'cancelled'"></app-results-table>
</div>


