<div class="page-container">

  <h1>Once Off Assessments</h1>

  <div class="audit-tool-cards-container">
    <div class="audit-tool-card more-list-screen-card">
      <mat-tab-group #tabGroup dynamicHeight="true" mat-align-tabs="center" [(selectedIndex)]="selectedGroup" (selectedTabChange)="tabChanged($event)">
        <mat-tab label="{{surveyGroup}}" *ngFor="let surveyGroup of surveyGroups">
          <ng-template mat-tab-label>
            {{surveyGroup}}
          </ng-template>

          <table mat-table [dataSource]="surveysToShow">
            <ng-container matColumnDef="tags">
              <th mat-header-cell *matHeaderCellDef class="survey-details-link center-align">
                <h4>Tags</h4>
              </th>
              <td mat-cell *matCellDef="let survey" class="survey-details-link">
                <div class="center-align">
                  <app-tags-cell-display [tags]="survey['tagList']" [contextName]="survey['title']"></app-tags-cell-display>
                </div>
              </td>           
            </ng-container>            
            <ng-container matColumnDef="survey">
              <th mat-header-cell *matHeaderCellDef class="survey-details-link center-align"> 
                <h4>Assessment1</h4> 
              </th>
              <td mat-cell *matCellDef="let survey" class="survey-details-link">
                <div class="center-align">
                  <h3>
                    {{survey['title'] | unescape}}
                  </h3>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="dueDate">
              <th mat-header-cell *matHeaderCellDef class="survey-details-link center-align"> 
                <h4>Due Date</h4> 
              </th>
              <td mat-cell *matCellDef="let survey" class="survey-details-link">
                <div class="center-align">
                  <h4>  {{ survey.dueDate| date :'dd MMM y'  }} </h4>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="%Complete">
              <th mat-header-cell *matHeaderCellDef class="survey-details-link center-align"> 
                <h4>% Complete</h4> 
              </th>
              <td mat-cell *matCellDef="let survey" class="survey-details-link">
                <div *ngIf="!survey['avgCompletionRate'] && survey['avgCompletionRate'] != 0 && user['role'] === 'NationalView'">
                  <p class="center-align">Cancelled Assessment</p>
                </div>
                <div *ngIf="!survey['avgCompletionRate'] && survey['avgCompletionRate'] != 0 && user['role'] !== 'NationalView'">
                  <p class="center-align">This Assessment is not <br> available in your <span *ngIf="user['role'] === 'RegionManager'">Region</span><span *ngIf="user['role'] === 'AreaManager'">Area</span><span *ngIf="user['role'] === 'GroupManager'">Group</span><span *ngIf="user['role'] === 'OperationManager'">Zone</span><span *ngIf="user['role'] === 'StateManager'">State</span></p>
                </div>
                <div class="center-align survey-stat" *ngIf="survey['avgCompletionRate'] || survey['avgCompletionRate'] == 0">
                  <h1>{{survey['avgCompletionRate'] | percent}}</h1>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef class="survey-details-link center-align hide-on-mobile"> 
                <h4>Open/Created Actions</h4> 
              </th>
              <td mat-cell *matCellDef="let survey" class="survey-details-link hide-on-mobile">
                <div class="center-align">
                  <h4 *ngIf="!survey['actionsOutstanding'] && !survey['actionsCreated']">0 / 0 </h4>
                  <h4 *ngIf="!survey['actionsOutstanding'] && survey['actionsCreated']">0 / {{survey['actionsCreated']}} </h4>
                  <h4 *ngIf="survey['actionsOutstanding'] && survey['actionsCreated']">{{survey['actionsOutstanding']}} / {{survey['actionsCreated']}} </h4>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="questions">
              <th mat-header-cell *matHeaderCellDef class="survey-details-link hide-on-mobile center-align"> 
                <h4>Number of Questions</h4> 
              </th>
              <td mat-cell *matCellDef="let survey" class="survey-details-link hide-on-mobile">
                <div class="center-align">
                  <h4>{{survey['questions']?.length}}</h4>
                </div>
              </td>
            </ng-container>

            <mat-header-row *matHeaderRowDef="tableColumns"  class="survey-heading-line"></mat-header-row>
            <mat-row class="survey-line survey-line-link" *matRowDef="let survey; columns: tableColumns;" (click)="saveState = true; surveyDetails(survey['avgCompletionRate'])" [routerLink]="['/above-store-landing/once-off-survey-details', survey['id']]"></mat-row>
          </table>

          <div *ngIf="isLoading" class="loader-container">
            <div class="loading-wapple"></div>
          </div>

          <div *ngIf="surveysToShow.length == 0 && !isLoading">
            <h3 class="none-found-message">
              You currently have no {{surveyGroup}} Assessments. Watch this space to receive Assessments once they are published.
            </h3>
          </div>

          <mat-paginator #paginator
              [pageIndex]=pageIndex
              [pageSize]=pageSize
              [length]="totalCount"
              [pageSizeOptions]="[5, 10, 25, 100]"
              [showFirstLastButtons]=false
              (page)="getNext($event)">
          </mat-paginator>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>



