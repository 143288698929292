<div class="panel">
    <h1 class="heading">My To Do List</h1>
    <mat-tab-group>
        <mat-tab label="Available">
            <ng-container *ngTemplateOutlet="thisAvailable"></ng-container>
        </mat-tab>
        <mat-tab label="Due This Week">
            <ng-container *ngTemplateOutlet="thisWeek"></ng-container>
        </mat-tab>
        <mat-tab label="Due Next Week">
            <ng-container *ngTemplateOutlet="nextWeek"></ng-container>
        </mat-tab>
    </mat-tab-group>
</div>

<ng-template #thisAvailable>
    <!-- empty state -->
    <div *ngIf="!isToDoListAvailableLoading && !noUserAccess && isToDoListAvailableEmpty" class="emptyOrLoadingState">
        No items due.
    </div>

    <!-- loading state -->
    <div *ngIf="isToDoListAvailableLoading" class="emptyOrLoadingState">
        Loading...
    </div>

    <!-- user access state -->
    <div *ngIf="!isToDoListAvailableLoading && noUserAccess" 
        class="emptyOrLoadingState">
        No user access found
    </div>

    <app-accordion [headingLevel]="2" [expanded]="toDoListAccordionState['available']['once-off']" (toggleClick)="onChangeAccordion('available', 'once-off', $event)" *ngIf="('storeToDoList.viewOnceOffAssessments' | accessCheck) && toDoListAvailable?.surveys?.standard?.length > 0">
        <ng-container accordion-header>
            Once Off Assessments
        </ng-container>
        <ng-container accordion-body>
            <app-to-do-list-item-card *ngFor="let survey of toDoListAvailable.surveys.standard; let i = index;"
                [buttonRouterLink]="['/store-landing/store-survey', survey.id]"
                [completionPercentage]="survey.completion * 100" [title]="survey.title" [dueDate]="survey.dueDate"
                [number]="i + 1" [buttonText]="survey.status === 'started' ? 'Continue' : 'Start'"
                [totalNumberOfItemsInList]="toDoListAvailable.surveys.standard.length"></app-to-do-list-item-card>
        </ng-container>
    </app-accordion>

    <app-accordion [headingLevel]="2" [expanded]="toDoListAccordionState['available']['adhoc']" (toggleClick)="onChangeAccordion('available', 'adhoc', $event)" *ngIf="('storeToDoList.viewAdhocAssessments' | accessCheck) && toDoListAvailable?.surveys?.adhoc?.length > 0">
        <ng-container accordion-header>
            Adhoc Assessments
        </ng-container>
        <ng-container accordion-body>
            <app-to-do-list-item-card *ngFor="let survey of toDoListAvailable.surveys.adhoc; let i = index;"
                [buttonRouterLink]="['/onthefly-survey-details', survey.survey_id]"
                [title]="survey.title"
                [buttonText]="'Start'"
                [number]="i + 1"
                [totalNumberOfItemsInList]="toDoListAvailable.surveys.adhoc.length">
            </app-to-do-list-item-card>
        </ng-container>
    </app-accordion>

    <app-accordion [headingLevel]="2" [expanded]="toDoListAccordionState['available']['recurring']" (toggleClick)="onChangeAccordion('available', 'recurring', $event)" *ngIf="('storeToDoList.viewRecurringAssessments' | accessCheck) && toDoListAvailable?.surveys?.recurring?.length > 0">
        <ng-container accordion-header>
            Recurring Assessments
        </ng-container>
        <ng-container accordion-body>
            <app-to-do-list-item-card *ngFor="let survey of toDoListAvailable.surveys.recurring; let i = index;"
                [buttonRouterLink]="['/store-landing/survey-details', survey.id]"
                [completionPercentage]="survey.completion * 100" [title]="survey.title" [dueDate]="survey.dueDate"
                [number]="i + 1" [buttonText]="survey.status === 'started' ? 'Continue' : 'Start'"
                [totalNumberOfItemsInList]="toDoListAvailable.surveys.recurring.length"></app-to-do-list-item-card>
        </ng-container>
    </app-accordion>

    <app-accordion [headingLevel]="2" [expanded]="toDoListAccordionState['available']['mrr']" (toggleClick)="onChangeAccordion('available', 'mrr', $event)" *ngIf="('storeToDoList.viewMaterialRiskAssessments' | accessCheck) && toDoListAvailable?.surveys?.mrr?.length > 0">
        <ng-container accordion-header>
            Material Risk Assessments
        </ng-container>
        <ng-container accordion-body>
            <app-to-do-list-item-card *ngFor="let survey of toDoListAvailable.surveys.mrr; let i = index;"
                [buttonRouterLink]="['/mrr-response', survey.id]"
                [completionPercentage]="survey.completion * 100" [title]="survey.title" [dueDate]="survey.dueDate"
                [number]="i + 1" [buttonText]="survey.status === 'started' ? 'Continue' : 'Start'"
                [totalNumberOfItemsInList]="toDoListAvailable.surveys.mrr.length"></app-to-do-list-item-card>
        </ng-container>
    </app-accordion>

    <app-accordion [headingLevel]="2" [expanded]="toDoListAccordionState['available']['standard_actions']" (toggleClick)="onChangeAccordion('available', 'standard_actions', $event)" *ngIf="('storeToDoList.viewStandardActions' | accessCheck) && toDoListAvailable?.actions?.standard?.length > 0">
        <ng-container accordion-header>
            Standard Actions
        </ng-container>
        <ng-container accordion-body>
            <app-to-do-list-item-card *ngFor="let action of toDoListAvailable.actions.standard; let i = index;"
                [buttonRouterLink]="['/action-details', action.id]" [title]="action.title"
                [dueDate]="action.dueDate" [buttonText]="'Update'" [number]="i + 1" [totalNumberOfItemsInList]="toDoListAvailable.actions.standard.length"></app-to-do-list-item-card>
        </ng-container>
    </app-accordion>

    <app-accordion [headingLevel]="2" [expanded]="toDoListAccordionState['available']['mrr_actions']" (toggleClick)="onChangeAccordion('available', 'mrr_actions', $event)" *ngIf="('storeToDoList.viewMaterialRiskActions' | accessCheck) && toDoListAvailable?.actions?.mrr_actions?.length > 0">
        <ng-container accordion-header>
            Material Risk Actions
        </ng-container>
        <ng-container accordion-body>
            <app-to-do-list-item-card *ngFor="let action of toDoListAvailable.actions.mrr_actions; let i = index;"
                [buttonRouterLink]="['/mrr-action-details', action.id]" [title]="action.title"
                [dueDate]="action.dueDate" [buttonText]="'Update'" [number]="i + 1" [totalNumberOfItemsInList]="toDoListAvailable.actions.mrr_actions.length"></app-to-do-list-item-card>
        </ng-container>
    </app-accordion>

</ng-template>

<ng-template #thisWeek>
    <!-- empty state -->
    <div *ngIf="!isToDoListThisWeekLoading && !noUserAccess && isToDoListThisWeekEmpty" class="emptyOrLoadingState">
        No items due this week.
    </div>

    <!-- loading state -->
    <div *ngIf="isToDoListThisWeekLoading" class="emptyOrLoadingState">
        Loading...
    </div>

    <!-- user access state -->
    <div *ngIf="!isToDoListAvailableLoading && noUserAccess" 
        class="emptyOrLoadingState">
        No user access found
    </div>

    <app-accordion [headingLevel]="2" [expanded]="toDoListAccordionState['thisWeek']['once-off']" (toggleClick)="onChangeAccordion('thisWeek', 'once-off', $event)" *ngIf="('storeToDoList.viewOnceOffAssessments' | accessCheck) && toDoListThisWeek?.surveys?.standard?.length > 0">
        <ng-container accordion-header>
            Once Off Assessments
        </ng-container>
        <ng-container accordion-body>
            <app-to-do-list-item-card *ngFor="let survey of toDoListThisWeek.surveys.standard; let i = index;"
                [buttonRouterLink]="['/store-landing/store-survey', survey.id]"
                [completionPercentage]="survey.completion * 100" [title]="survey.title" [dueDate]="survey.dueDate"
                [number]="i + 1" [buttonText]="survey.status === 'started' ? 'Continue' : 'Start'"
                [totalNumberOfItemsInList]="toDoListThisWeek.surveys.standard.length"></app-to-do-list-item-card>
        </ng-container>
    </app-accordion>

    <app-accordion [headingLevel]="2" [expanded]="toDoListAccordionState['thisWeek']['adhoc']" (toggleClick)="onChangeAccordion('thisWeek', 'adhoc', $event)" *ngIf="('storeToDoList.viewAdhocAssessments' | accessCheck) && toDoListThisWeek?.surveys?.adhoc?.length > 0">
        <ng-container accordion-header>
            Adhoc Assessments
        </ng-container>
        <ng-container accordion-body>
            <app-to-do-list-item-card *ngFor="let survey of toDoListThisWeek.surveys.adhoc; let i = index;"
                [buttonRouterLink]="['/onthefly-survey-details', survey.survey_id]" [completionPercentage]="survey.completion * 100"
                [title]="survey.title" [dueDate]="survey.dueDate" [number]="i + 1"
                [buttonText]="survey.status === 'started' ? 'Continue' : 'Start'"
                [totalNumberOfItemsInList]="toDoListThisWeek.surveys.adhoc.length"></app-to-do-list-item-card>
        </ng-container>
    </app-accordion>

    <app-accordion [headingLevel]="2" [expanded]="toDoListAccordionState['thisWeek']['recurring']" (toggleClick)="onChangeAccordion('thisWeek', 'recurring', $event)" *ngIf="('storeToDoList.viewRecurringAssessments' | accessCheck) && toDoListThisWeek?.surveys?.recurring?.length > 0">
        <ng-container accordion-header>
            Recurring Assessments
        </ng-container>
        <ng-container accordion-body>
            <app-to-do-list-item-card *ngFor="let survey of toDoListThisWeek.surveys.recurring; let i = index;"
                [buttonRouterLink]="['/store-landing/survey-details', survey.id]"
                [completionPercentage]="survey.completion * 100" [title]="survey.title" [dueDate]="survey.dueDate"
                [number]="i + 1" [buttonText]="survey.status === 'started' ? 'Continue' : 'Start'"
                [totalNumberOfItemsInList]="toDoListThisWeek.surveys.recurring.length"></app-to-do-list-item-card>
        </ng-container>
    </app-accordion>

    <app-accordion [headingLevel]="2" [expanded]="toDoListAccordionState['thisWeek']['mrr']" (toggleClick)="onChangeAccordion('thisWeek', 'mrr', $event)" *ngIf="('storeToDoList.viewMaterialRiskAssessments' | accessCheck) && toDoListThisWeek?.surveys?.mrr?.length > 0">
        <ng-container accordion-header>
            Material Risk Assessments
        </ng-container>
        <ng-container accordion-body>
            <app-to-do-list-item-card *ngFor="let survey of toDoListThisWeek.surveys.mrr; let i = index;"
                [buttonRouterLink]="['/mrr-response', survey.id]"
                [completionPercentage]="survey.completion * 100" [title]="survey.title" [dueDate]="survey.dueDate"
                [number]="i + 1" [buttonText]="survey.status === 'started' ? 'Continue' : 'Start'"
                [totalNumberOfItemsInList]="toDoListThisWeek.surveys.mrr.length"></app-to-do-list-item-card>
        </ng-container>
    </app-accordion>

    <app-accordion [headingLevel]="2" [expanded]="toDoListAccordionState['thisWeek']['standard_actions']" (toggleClick)="onChangeAccordion('thisWeek', 'standard_actions', $event)" *ngIf="('storeToDoList.viewStandardActions' | accessCheck) && toDoListThisWeek?.actions?.standard?.length > 0">
        <ng-container accordion-header>
            Standard Actions
        </ng-container>
        <ng-container accordion-body>
            <app-to-do-list-item-card *ngFor="let action of toDoListThisWeek.actions.standard; let i = index;"
                [buttonRouterLink]="['/action-details', action.id]" [title]="action.title"
                [dueDate]="action.dueDate" [buttonText]="'Update'" [number]="i + 1" [totalNumberOfItemsInList]="toDoListThisWeek.actions.standard.length"></app-to-do-list-item-card>
        </ng-container>
    </app-accordion>

    <app-accordion [headingLevel]="2" [expanded]="toDoListAccordionState['thisWeek']['mrr_actions']" (toggleClick)="onChangeAccordion('thisWeek', 'mrr_actions', $event)" *ngIf="('storeToDoList.viewMaterialRiskActions' | accessCheck) && toDoListThisWeek?.actions?.mrr_actions?.length > 0">
        <ng-container accordion-header>
            Material Risk Actions
        </ng-container>
        <ng-container accordion-body>
            <app-to-do-list-item-card *ngFor="let action of toDoListThisWeek.actions.mrr_actions; let i = index;"
                [buttonRouterLink]="['/mrr-action-details', action.id]" [title]="action.title"
                [dueDate]="action.dueDate" [buttonText]="'Update'" [number]="i + 1" [totalNumberOfItemsInList]="toDoListThisWeek.actions.mrr_actions.length"></app-to-do-list-item-card>
        </ng-container>
    </app-accordion>

</ng-template>

<ng-template #nextWeek>
    <!-- empty state -->
    <div *ngIf="!isToDoListNextWeekLoading && !noUserAccess && isToDoListNextWeekEmpty" class="emptyOrLoadingState">
        No items due next week.
    </div>

    <!-- loading state -->
    <div *ngIf="isToDoListNextWeekLoading" class="emptyOrLoadingState">
        Loading...
    </div>

    <!-- user access state -->
    <div *ngIf="!isToDoListAvailableLoading && noUserAccess"  
        class="emptyOrLoadingState">
        No user access found
    </div>

    <app-accordion [headingLevel]="2" [expanded]="toDoListAccordionState['nextWeek']['once-off']" (toggleClick)="onChangeAccordion('nextWeek', 'once-off', $event)" *ngIf="('storeToDoList.viewOnceOffAssessments' | accessCheck) && toDoListNextWeek?.surveys?.standard?.length > 0">
        <ng-container accordion-header>
            Once Off Assessments
        </ng-container>
        <ng-container accordion-body>
            <app-to-do-list-item-card *ngFor="let survey of toDoListNextWeek.surveys.standard; let i = index"
                [buttonRouterLink]="['/store-landing/store-survey', survey.id]"
                [completionPercentage]="survey.completion * 100" [title]="survey.title" [dueDate]="survey.dueDate"
                [number]="i + 1" [buttonText]="survey.status === 'started' ? 'Continue' : 'Start'"
                [totalNumberOfItemsInList]="toDoListNextWeek.surveys.standard.length"></app-to-do-list-item-card>
        </ng-container>
    </app-accordion>

    <app-accordion [headingLevel]="2" [expanded]="toDoListAccordionState['nextWeek']['adhoc']" (toggleClick)="onChangeAccordion('nextWeek', 'adhoc', $event)" *ngIf="('storeToDoList.viewAdhocAssessments' | accessCheck) && toDoListNextWeek?.surveys?.adhoc?.length > 0">
        <ng-container accordion-header>
            Adhoc Assessments
        </ng-container>
        <ng-container accordion-body>
            <app-to-do-list-item-card *ngFor="let survey of toDoListNextWeek.surveys.adhoc; let i = index"
                [buttonRouterLink]="['/onthefly-survey-details', survey.survey_id]" [completionPercentage]="survey.completion * 100"
                [title]="survey.title" [dueDate]="survey.dueDate" [number]="i + 1"
                [buttonText]="survey.status === 'started' ? 'Continue' : 'Start'"
                [totalNumberOfItemsInList]="toDoListNextWeek.surveys.adhoc.length"></app-to-do-list-item-card>
        </ng-container>
    </app-accordion>

    <app-accordion [headingLevel]="2" [expanded]="toDoListAccordionState['nextWeek']['recurring']" (toggleClick)="onChangeAccordion('nextWeek', 'recurring', $event)" *ngIf="('storeToDoList.viewRecurringAssessments' | accessCheck) && toDoListNextWeek?.surveys?.recurring?.length > 0">
        <ng-container accordion-header>
            Recurring Assessments
        </ng-container>
        <ng-container accordion-body>
            <app-to-do-list-item-card *ngFor="let survey of toDoListNextWeek.surveys.recurring; let i = index"
                [buttonRouterLink]="['/store-landing/survey-details', survey.id]"
                [completionPercentage]="survey.completion * 100" [title]="survey.title" [dueDate]="survey.dueDate"
                [number]="i + 1" [buttonText]="survey.status === 'started' ? 'Continue' : 'Start'"
                [totalNumberOfItemsInList]="toDoListNextWeek.surveys.recurring.length"></app-to-do-list-item-card>
        </ng-container>
    </app-accordion>

    <app-accordion [headingLevel]="2" [expanded]="toDoListAccordionState['nextWeek']['mrr']" (toggleClick)="onChangeAccordion('nextWeek', 'mrr', $event)" *ngIf="('storeToDoList.viewMaterialRiskAssessments' | accessCheck) && toDoListNextWeek?.surveys?.mrr?.length > 0">
        <ng-container accordion-header>
            Material Risk Assessments
        </ng-container>
        <ng-container accordion-body>
            <app-to-do-list-item-card *ngFor="let survey of toDoListNextWeek.surveys.mrr; let i = index"
                [buttonRouterLink]="['/mrr-response', survey.id]"
                [completionPercentage]="survey.completion * 100" [title]="survey.title" [dueDate]="survey.dueDate"
                [number]="i + 1" [buttonText]="survey.status === 'started' ? 'Continue' : 'Start'"
                [totalNumberOfItemsInList]="toDoListNextWeek.surveys.mrr.length"></app-to-do-list-item-card>
        </ng-container>
    </app-accordion>

    <app-accordion [headingLevel]="2" [expanded]="toDoListAccordionState['nextWeek']['standard_actions']" (toggleClick)="onChangeAccordion('nextWeek', 'standard_actions', $event)" *ngIf="('storeToDoList.viewStandardActions' | accessCheck) && toDoListNextWeek?.actions?.standard?.length > 0">
        <ng-container accordion-header>
            Standard Actions
        </ng-container>
        <ng-container accordion-body>
            <app-to-do-list-item-card *ngFor="let action of toDoListNextWeek.actions.standard; let i = index;"
                [buttonRouterLink]="['/action-details', action.id]" [title]="action.title"
                [dueDate]="action.dueDate" [buttonText]="'Update'" [number]="i + 1" [totalNumberOfItemsInList]="toDoListNextWeek.actions.standard.length"></app-to-do-list-item-card>
        </ng-container>
    </app-accordion>

    <app-accordion [headingLevel]="2" [expanded]="toDoListAccordionState['nextWeek']['mrr_actions']" (toggleClick)="onChangeAccordion('nextWeek', 'mrr_actions', $event)" *ngIf="('storeToDoList.viewMaterialRiskActions' | accessCheck) && toDoListNextWeek?.actions?.mrr_actions?.length > 0">
        <ng-container accordion-header>
            Material Risk Actions
        </ng-container>
        <ng-container accordion-body>
            <app-to-do-list-item-card *ngFor="let action of toDoListNextWeek.actions.mrr_actions; let i = index;"
                [buttonRouterLink]="['/mrr-action-details', action.id]" [title]="action.title"
                [dueDate]="action.dueDate" [buttonText]="'Update'" [number]="i + 1" [totalNumberOfItemsInList]="toDoListNextWeek.actions.mrr_actions.length"></app-to-do-list-item-card>
        </ng-container>
    </app-accordion>
</ng-template>